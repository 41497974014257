import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import TeamBg from "../images/passionate-bg.png";

const Passionate = ({ heading, tagline, content, items }) => {
	return (
		<section className="my-8" style={sectionWrapper(TeamBg)}>
			<Container>
				<Row>
					<Col
						lg={6}
						className={`mb-4 mb-lg-0 text-start text-md-start pe-lg-4`}
					>
						<span className="text-light-black">{tagline}</span>
						<h2 className="text-light-black my-0 pt-2">{heading}</h2>
						<div
							className="text-light-black py-4"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
					<Col
						lg={6}
						className={`mb-4 mb-lg-0 text-start text-md-start ps-md-4 position-relative`}
					>
						<div className="border-2 border rounded-5 border-black">
							{items.map((item, i) => (
								<div
									className={`p-4 ${
										items.length !== i + 1
											? "border-bottom border-2 border-black"
											: ""
									}`}
								>
									<div className="d-flex justify-content-between pb-4 align-items-center">
										<h3
											className="m-0 fw-black montserrat-black"
											style={{ color: item.passionateItemColor }}
										>
											{item.passionateItemHeading}
										</h3>
										<span
											className="fs-4 fw-black montserrat-black"
											style={{ color: item.passionateItemColor }}
										>
											0{i + 1}
										</span>
									</div>
									<p className="m-0 fs-6 text-light-black">
										{item.passionateItemContent}
									</p>
								</div>
							))}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "bottom left",
	backgroundSize: "35%",
});

export default Passionate;
