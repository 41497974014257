import { useEffect, useState } from "react";

export default function useOnScreen(ref, rootMargin = "0px") {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true); // Set the component as mounted

		const observer = new IntersectionObserver(
			([entry]) => {
				if (isMounted) {
					// Update our state when observer callback fires
					setIntersecting(entry.isIntersecting);
				}
			},
			{
				rootMargin,
			}
		);

		// Check and observe the element if it's available
		if (ref.current) {
			observer.observe(ref.current);
		} else {
		}

		return () => {
			// Unobserve the element if it's still available and component is mounted
			if (ref.current && isMounted) {
				observer.unobserve(ref.current);
			}
			setIsMounted(false); // Set the component as unmounted
		};
	}, [isMounted]); // Adding isMounted to dependencies to re-run the effect if it changes

	return isIntersecting;
}
