import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaLinkedin } from "react-icons/fa";
import TeamBg from "../images/our-team-bg.png";

const OurTeam = ({ heading, tagline, content, ctaButton, teamsMember }) => {
	return (
		<section
			id="team"
			className="bg-blue-bg py-5 py-md-8"
			style={sectionWrapper(TeamBg)}
		>
			<Container>
				<Row>
					<Col
						lg={3}
						className={`mb-4 mb-lg-0 text-start text-md-start pe-lg-4`}
					>
						<span className="text-white">{tagline}</span>
						<h2 className="my-0 pt-2 text-white">{heading}</h2>
						<div
							className="text-white py-4"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
						<Button
							as={Link}
							href={ctaButton.url}
							size="lg"
							className="px-4 py-3 w-100 w-md-auto montserrat-bold fs-6"
							target={ctaButton.target}
							variant="secondary"
						>
							{ctaButton.title}
						</Button>
					</Col>
					<Col
						lg={9}
						className="mb-4 mb-lg-0 text-start text-md-start ps-md-4 position-relative"
					>
						<Row>
							{teamsMember.map((item, i) => (
								<Col lg={6} key={i} className="px-4 mb-6">
									<GatsbyImage
										image={
											item.teamPostFields?.teamMemberImage.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={item.teamPostFields?.teamMemberImage.node?.altText}
										className="border border-white border-2 rounded-5"
										style={{ height: "400px", width: "100%" }}
									/>
									<div className="d-flex border-b border-white pt-4 pb-3 justify-content-between">
										<div>
											<h4 className="text-white fs-5 mb-0 montserrat-semibold">
												{item.title}
											</h4>
											<p className="text-white mb-0">
												{item.teamPostFields.teamMemberDesignation}
											</p>
										</div>
										<a
											href={item.teamPostFields.teamMemberLinkedinUrl}
											target="_blank"
											rel="noreferrer"
										>
											<FaLinkedin className="fs-3 text-white" />
										</a>
									</div>
									<Accordion>
										<Accordion.Item
											eventKey="0"
											className="bg-transparent border-top-0 border-end-0 border-start-0"
										>
											<Accordion.Header>Favorite quote</Accordion.Header>
											<Accordion.Body>
												<div
													className="text-white"
													dangerouslySetInnerHTML={{
														__html: item.teamPostFields.teamMemberFavoriteQuote,
													}}
												></div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item
											eventKey="1"
											className="bg-transparent border-top-0 border-end-0 border-start-0"
										>
											<Accordion.Header>Experience</Accordion.Header>
											<Accordion.Body>
												<div
													className="text-white"
													dangerouslySetInnerHTML={{
														__html: item.teamPostFields.teamMemberExperience,
													}}
												></div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item
											eventKey="2"
											className="bg-transparent border-top-0 border-end-0 border-start-0"
										>
											<Accordion.Header>Key accomplishments</Accordion.Header>
											<Accordion.Body>
												<div
													className="text-white"
													dangerouslySetInnerHTML={{
														__html:
															item.teamPostFields.teamMemberKeyAccomplishments,
													}}
												></div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item
											eventKey="3"
											className="bg-transparent border-top-0 border-end-0 border-start-0"
										>
											<Accordion.Header>Interests</Accordion.Header>
											<Accordion.Body>
												<div
													className="text-white"
													dangerouslySetInnerHTML={{
														__html: item.teamPostFields.teamMemberInterests,
													}}
												></div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item
											eventKey="4"
											className="bg-transparent border-top-0 border-end-0 border-start-0 rounded-0"
										>
											<Accordion.Header>Pets</Accordion.Header>
											<Accordion.Body>
												<div
													className="text-white"
													dangerouslySetInnerHTML={{
														__html: item.teamPostFields.teamMemberPets,
													}}
												></div>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "top left",
	backgroundSize: "35%",
});

export default OurTeam;
