import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import React, { useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useOnScreen from "./use-on-screen"; // Import the hook

const OurStory = ({
	heading,
	tagline,
	content,
	title2,
	content2,
	bgImage,
	ourStoryItems,
}) => {
	return (
		<section className="py-8" style={sectionWrapper(bgImage)}>
			<Container>
				<Row className="align-items-lg-center justify-content-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-center">
						<h2 className="my-auto text-white pb-2">{heading}</h2>
						<div
							className="text-white py-4"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
				</Row>
			</Container>
			{ourStoryItems && ourStoryItems.length > 0 && (
				<div className="story-item my-4 position-relative">
					<Container>
						<div className="story-item-wrapper py-6">
							{ourStoryItems.map((item, i) => (
								<FadeInRow key={i} item={item} />
							))}
						</div>
					</Container>
				</div>
			)}
			<Container>
				<Row className="align-items-lg-center justify-content-center pt-4">
					<Col lg={7} className="mb-4 mb-lg-0 text-center">
						<span className="text-secondary">{tagline}</span>
						<h2 className="my-auto text-white py-2">{title2}</h2>
						<div
							className="text-white py-4"
							dangerouslySetInnerHTML={{ __html: content2 }}
						></div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

// This function returns the background style with linear-gradient and image.
export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) center / cover no-repeat`,
});

const FadeInRow = ({ item }) => {
	const ref = useRef(null);
	const onScreen = useOnScreen(ref, "-100px");

	useEffect(() => {
		if (onScreen) {
			gsap.to(ref.current, {
				opacity: 1,
				y: 0,
				duration: 1,
				ease: "power1.out",
			});
		}
	}, [onScreen]);

	return (
		<Row
			ref={ref}
			className="position-relative py-7 my-5 story-item-content"
			style={{ opacity: 0, transform: "translateY(100px)" }}
		>
			<Col lg={6} className="px-6 text-md-end story-item-info">
				<h4 className="text-white text-start text-lg-end">
					{item.ourStoryItemHeading}
				</h4>
			</Col>
			<div className="story-item-icon">
				<span></span>
			</div>
			<Col lg={6} className="px-6 story-item-info">
				<div className="cbp_tmlabel">
					<div
						className="text-white pb-4"
						dangerouslySetInnerHTML={{ __html: item.ourStoryItemContent }}
					></div>
					<GatsbyImage
						image={
							item?.outStoryItemImage?.node?.localFile.childImageSharp
								.gatsbyImageData
						}
						alt={item?.outStoryItemImage?.node?.altText}
						className="border border-white rounded-5"
						style={{ maxHeight: "450px" }}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default OurStory;
